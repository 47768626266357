<template>
  <div class="address-op">
    <el-row>
      当前地址:<span>{{currentAddress}}</span>
    </el-row>
    <el-row>
      当前是否绑定地址权限:<span>{{ownPermission}}</span>
      <el-button v-if="!ownPermission" type="primary" size="mini" @click="bindClick">扫码绑定</el-button>
    </el-row>
    <el-row v-if="permissionAddress != null">
      绑定的地址:<span>{{permissionAddress}}</span>
    </el-row>
    <el-row v-if="permissionAddress != null">
      <el-button type="primary" size="mini" @click="unbindClick">解除绑定</el-button>
    </el-row>
    <el-divider></el-divider>
    <el-row>
      发送交易:<el-button type="primary" size="mini" @click="sendScanClick">扫码</el-button>
    </el-row>
    <el-row>
      <el-input type="textarea" :rows="4" v-model="txInput"></el-input>
      <el-button type="primary" size="mini" @click="sendRawTxBtn">发送交易</el-button>
    </el-row>
    <el-divider></el-divider>
    <el-row>
      构建trx转账交易:
    </el-row>
    <el-row>
      转到地址:<el-input v-model="trxTx.toAddress" placeholder="请输入地址" size="mini" clearable></el-input>
      金额:<el-input-number v-model="trxTx.amount" size="mini"></el-input-number>
      <el-button type="primary" size="mini" @click="buildTrxTx">构建交易</el-button>
    </el-row>
    <el-divider></el-divider>
    <el-row>
      构建trc20转账交易:
    </el-row>
    <el-row>
      合约地址:
      <el-select size="mini" v-model="trc20Tx.contract" @change="contractChange">
        <el-option
            v-for="item in contracts"
            :key="item.contract"
            :label="item.name"
            :value="item.contract">
        </el-option>
      </el-select><br>
      <el-button type="primary" size="mini" @click="addContract">自定义trc20代币</el-button><br>
      转到地址:<el-input placeholder="请输入地址" v-model="trc20Tx.toAddress" size="mini" clearable></el-input>
      小数点位数:<el-input-number v-model="trc20Tx.decimals" size="mini"></el-input-number><br>
      金额:<el-input-number v-model="trc20Tx.amount" size="mini"></el-input-number><br>
      <el-button type="primary" size="mini" @click="buildTrc20Tx">构建交易</el-button>
    </el-row>
    <el-divider></el-divider>
    <el-row>
      <el-button type="primary" size="mini" @click="increaseApproval">increaseApproval授权</el-button>
    </el-row>
    <el-divider></el-divider>
    <el-row>
      <qr-code ref="qrcode"></qr-code>
    </el-row>
    <scan-qr ref="addressOpScanQr"></scan-qr>
  </div>
</template>

<script>
import ScanQr from "@/components/ScanQr";
import QrCode from "@/components/QrCode";
export default {
  name: 'AddressOp',
  components: {QrCode, ScanQr},
  props: {
  },
  data:function () {
    return {
      currentAddress: null,
      ownPermission: false,
      permissionAddress: null,
      currentVersion: '',
      versions: ['4.2.0','5.1.0'],
      txInput: null,
      trxTx: {
        toAddress: 'TMKeNmLf8S1gGoRZBXFHyx7BBsbMLuE69F',
        amount: 1
      },
      trc20Tx: {
        name: '',
        contract:'',
        decimals:'',
        toAddress: 'TMKeNmLf8S1gGoRZBXFHyx7BBsbMLuE69F',
        amount: 1
      },
      contracts: [
        {
          name:'Tether USD',
          contract:'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t',
          decimals:6
        },
      ]
    }
  },
  mounted() {
    let that = this
    let c = this.getCookie('contracts')
    if (c!=null) {
      this.contracts = JSON.parse(c)
    }
    setTimeout(function (){
      that.currentVersion = that.getTronWeb().version
      console.log("当前tronWeb版本:",that.currentVersion)
      let flag = false
      for(let i =0;i<that.versions.length;i++) {
        if (that.versions[i] === that.currentVersion) {
          flag = true
        }
      }
      if (!flag) {
        alert("当前钱包环境版本与本工具支持不一致,版本:" + that.currentVersion + ",请联系开发支持,如继续使用,将按照版本5.1.0执行,可能存在错误");
        this.currentVersion = '5.1.0'
      }
      that.connectTron()
    },2000)
  },
  methods:{
    getTronWeb(){
      if (tronWeb) {
        return tronWeb
      }
      if (window.tronWeb) {
        return window.tronWeb
      }
      return null
    },
    connectTron(){
      if (this.getTronWeb() != null) {
        if (window.tronLink) {
          window.tronLink.request({method: 'tron_requestAccounts'}).then(requestAccountsResponse=>{
            if (requestAccountsResponse.code !== 200) {
              alert("请先批准连接")
              return
            }
            this.getCurrentAddress()
          }).catch(err =>{
            console.log("tron_requestAccounts err:",err)
          })
        } else {
          this.getCurrentAddress()
        }
      } else {
        alert("请使用chrome浏览器并安装tronLink插件")
      }
    },
    getCurrentAddress(){
      this.currentAddress = this.getTronWeb().defaultAddress.base58
      this.$store.commit('setAddress', this.currentAddress)
      this.getOwnBind()
    },
    getOwnBind(){
      this.getTronWeb().trx.getAccount(this.currentAddress).then(res=>{
        console.log('getOwnBind',res)
        let ownerPermission = res.owner_permission;
        if (ownerPermission !== undefined && ownerPermission.keys.length != 1) {
          let a0 = this.getTronWeb().address.fromHex(ownerPermission.keys[0].address)
          let a1 = this.getTronWeb().address.fromHex(ownerPermission.keys[1].address)
          if (a0 != this.currentAddress) {
            this.permissionAddress = a0
          } else {
            this.permissionAddress = a1
          }
          this.ownPermission = true
        } else {
          this.ownPermission = false
        }
        this.$store.commit('setOwnPermission', this.ownPermission)
      }).catch(err=>{
        console.log("getOwnBind:",err)
      })
    },
    bindClick(){
      this.$refs.addressOpScanQr.open(this.bind);
      // let address = prompt('请输入地址,注意,请确保地址是正确的,填写不正确地址会导致地址内财产丢失无法找回');
      // alert(address)
    },
    bind(address) {
      if (!address.startsWith('T')) {
        alert('二维码结果不是地址' + address)
        return
      }
      let flag = confirm("此操作会将地址["+this.currentAddress+"]的交易强制要求地址[" + address + "]同时签名,确定要继续吗?")
      if (!flag) {
        return;
      }
      let cA = this.getTronWeb().address.toHex(this.currentAddress)
      let tA = this.getTronWeb().address.toHex(address)
      let ownerPermission = {type: 0, permission_name: 'owner'};
      ownerPermission.threshold = 2;
      ownerPermission.keys = [];
      ownerPermission.keys.push({address: cA, weight: 1});
      ownerPermission.keys.push({address: tA, weight: 1});

      let activePermission = {type: 2, permission_name: 'active'};
      activePermission.threshold = 2;
      // 所有权限都给上限制
      activePermission.operations = '77ff07c0027ec307000000000000000000000000000000000000000000000000';
      activePermission.keys = [];
      activePermission.keys.push({address: cA, weight: 1});
      activePermission.keys.push({address: tA, weight: 1});
      let activeArray = []
      activeArray.push(activePermission)
      this.getTronWeb().transactionBuilder.updateAccountPermissions(cA, ownerPermission, null, activeArray).then(rawTransaction => {
        let flag = confirm("已生成交易,请使用钱包进行签名,会提示修改账户权限,放行即可,可以点击取消按钮取消本次操作")
        if (flag) {
          this.getTronWeb().trx.sign(rawTransaction).then(txObj => {
            flag = confirm("交易已签名,请确认上链,可以点击取消按钮取消本次操作")
            if (flag) {
              this.getTronWeb().trx.sendRawTransaction(txObj).then(res => {
                alert("res:" + JSON.stringify(res))
                console.log(res)
                if (res.result) {
                  alert("更新成功")
                }
              }).catch(e => console.log("sendRawTransaction",e));
            }
          }).catch(err => {console.log("multiSign",err)})
        }
      }).catch(t =>{
        console.log("updateAccountPermissions",t)
        if (t === 'class org.tron.core.exception.ContractValidateException : ownerAddress account does not exist') {
          alert("当前地址未激活,请先转一笔trx到此地址以激活地址")
        }
      });
    },
    unbindClick(){
      this.unbind()
    },
    unbind(){
      let flag = confirm("此操作会将地址["+this.currentAddress+"]的交易去除强制要求地址[" + this.permissionAddress + "]同时签名,确定要继续吗?")
      if (!flag) {
        return;
      }
      let cA = this.getTronWeb().address.toHex(this.currentAddress)
      let ownerPermission = {type: 0, permission_name: 'owner'};
      ownerPermission.threshold = 1;
      ownerPermission.keys = [];
      ownerPermission.keys.push({address: cA, weight: 1});

      let activePermission = {type: 2, permission_name: 'active'};
      activePermission.threshold = 1;
      // 所有权限都给上限制
      activePermission.operations = '77ff07c0027ec307000000000000000000000000000000000000000000000000';
      activePermission.keys = [];
      activePermission.keys.push({address: cA, weight: 1});
      let activeArray = []
      activeArray.push(activePermission)
      console.log("updateAccountPermissions",this.getTronWeb().transactionBuilder.updateAccountPermissions)
      this.getTronWeb().transactionBuilder.updateAccountPermissions(cA, ownerPermission, null, activeArray).then(rawTransaction => {
        let flag = confirm("已生成交易,请使用钱包进行签名,会提示修改账户权限,放行即可,可以点击取消按钮取消本次操作")
        if (flag) {
          this.buildQrCode(rawTransaction)
        }
      }).catch(t =>{
        console.log("updateAccountPermissions",t)
      });
    },
    sendScanClick(){
      let that = this
      this.$refs.addressOpScanQr.open(function(res){
        that.txInput = res
      })
    },
    sendRawTxBtn(){
      let txObj = JSON.parse(this.txInput)
      console.log(txObj)
      this.sendRawTx(txObj);
    },
    sendRawTx(txObj){
      console.log("sendRawTx txObj",txObj)
      let txHex = txObj.txHex
      let param = {
        transaction:txHex
      }
      console.log(this.getTronWeb().eventServer)

      let xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function() {
        if (this.readyState == 4 && this.status == 200) {
          let res = JSON.parse(this.responseText)
          console.log("res",res)
          if (res.result) {
            let txId = res.txid
            alert("提交成功")
            console.log("txId",txId)
          } else if(res.code == "TRANSACTION_EXPIRATION_ERROR") {
            alert("发送失败了,原因是交易限时5分钟完成,加快一下操作")
          } else {
            alert("提交失败,错误原因:" + res.code)
          }
        }
      };
      xhttp.open("POST", this.getTronWeb().eventServer.host + '/wallet/broadcasthex', true);
      xhttp.send(JSON.stringify(param));

      // this.getTronWeb().providers.HttpProvider.prototype.request.call(this.getTronWeb().eventServer,'wallet/broadcasthex',param,"post").then(res => {
      //   console.log("res",res)
      //   if (res.result) {
      //     let txId = res.txid
      //     alert("提交成功")
      //     console.log("txId",txId)
      //   } else if(res.code == "TRANSACTION_EXPIRATION_ERROR") {
      //     alert("发送失败了,原因是交易限时5分钟完成,加快一下操作")
      //   } else {
      //     alert("提交失败,错误原因:" + res.code)
      //   }
      // }).catch(err=>{
      //   console.log("sendRawTx err",err)
      // })
    },
    buildTrxTx(){
      let amount = this.trxTx.amount * 1000000;
      let flag = confirm("请确认转账\nfrom:" + this.currentAddress + "\nto:" + this.trxTx.toAddress + "\namount:" + this.trxTx.amount)
      if (flag) {
        this.getTronWeb().transactionBuilder.sendTrx(this.trxTx.toAddress, amount, this.currentAddress,{ permissionId: 2}).then(rawTransaction => {
          this.buildQrCode(rawTransaction)
        }).catch(t =>{console.log(t)});
      }
    },

    addContract(){
      let contract = prompt("请输入合约地址")
      let contractHex = this.getTronWeb().address.toHex(contract)
      let addressHex = this.getTronWeb().defaultAddress.hex
      let that = this
      this.getTronWeb().transactionBuilder.triggerConstantContract(contractHex, "name()", {}, [],addressHex).then(function(res){
        let name = that.getTronWeb().toUtf8(res.constant_result[0].substr(128)).replaceAll('\x00','')
        that.getTronWeb().transactionBuilder.triggerConstantContract(contractHex, "decimals()", {}, [],addressHex).then(function(res){
          console.log(res.constant_result[0])
          let decimals = that.getTronWeb().toBigNumber(res.constant_result[0]).toString()
          that.contracts.push({
            contract:contract,
            name:name,
            decimals:decimals
          })
          // 写到cookie
          that.setCookie('contracts',JSON.stringify(that.contracts))
        }).catch(err =>{
          console.log("triggerConstantContract decimals()",err)
        })
      }).catch(err =>{
        console.log("triggerConstantContract name()",err)
      })
    },
    contractChange(contract){
      for (let i=0;i<this.contracts.length;i++) {
        if (this.contracts[i].contract === contract) {
          this.trc20Tx.name = this.contracts[i].name
          this.trc20Tx.decimals = this.contracts[i].decimals
          return
        }
      }
    },
    buildTrc20Tx(){
      let flag = confirm("请确认转账\ntoken:" + this.trc20Tx.name + "\ncontract:" + this.trc20Tx.contract + "\nfrom:" + this.currentAddress + "\nto:" + this.trc20Tx.toAddress + "\namount:" + this.trc20Tx.amount)
      if (flag) {
        let amount = this.trc20Tx.amount * Math.pow(10,this.trc20Tx.decimals);
        let contractHex = this.getTronWeb().address.toHex(this.trc20Tx.contract)
        let fromHex = this.getTronWeb().address.toHex(this.currentAddress)
        let parameter = [{type:'address',value:this.trc20Tx.toAddress},{type:'uint256',value:this.getTronWeb().toHex(amount)}];
        let options = {
          feeLimit:50000000,
          callValue: 0
        };
        console.log("start",contractHex,"transfer(address,uint256)", options,parameter,fromHex,"end")
        this.getTronWeb().transactionBuilder.triggerSmartContract(contractHex,"transfer(address,uint256)", options,parameter,fromHex).then(rawTransaction => {
          console.log("rawTransaction",rawTransaction.transaction)
          this.buildQrCode(rawTransaction.transaction)
        }).catch(err =>console.log(err));
      }
    },

    buildQrCode(rawTransaction){
      console.log("rawTransaction",rawTransaction)
      this.getTronWeb().transactionBuilder.extendExpiration(rawTransaction, 60 * 5).then(newRaw =>{
        console.log("newRaw",newRaw)

        this.getTronWeb().trx.multiSign(newRaw, undefined, 0).then(txObj => {
          console.log("txObj",txObj)
          let proto1 = this.getTronWeb().utils.transaction.txJsonToPb(txObj)
          console.log("proto",proto1);
          let obj = {
            type:"sign",
            txObjBase64:this.arrayBufferToBase64(proto1.serializeBinary()),
            signature: txObj.signature,
            url: window.location.href.indexOf("?") != -1 ? window.location.href.substring(0,window.location.href.indexOf("?")) :window.location.href
          }
          console.log("obj",obj);
          console.log(JSON.stringify(obj))
          this.$refs.qrcode.generateQr(JSON.stringify(obj))
          alert("二维码(下翻)已生成,请在app中选择地址[" + this.permissionAddress +"],并扫描二维码进行签名")
        }).catch(err => {
          console.log("multiSign",err)
          // 兼容使用signTransaction签名
          this.getTronWeb().trx.signTransaction(newRaw).then(tO=>{
            console.log("tO",tO)
            let proto1 = this.getTronWeb().utils.transaction.txJsonToPb(tO)
            console.log("proto",proto1);
            let obj = {
              type:"sign",
              txObjBase64:this.arrayBufferToBase64(proto1.serializeBinary()),
              signature: tO.signature,
              url: window.location.href.indexOf("?") != -1 ? window.location.href.substring(0,window.location.href.indexOf("?")) :window.location.href
            }
            console.log("obj",obj);
            console.log(JSON.stringify(obj))
            this.$refs.qrcode.generateQr(JSON.stringify(obj))
            alert("二维码(下翻)已生成,请在app中选择地址[" + this.permissionAddress +"],并扫描二维码进行签名")
          })
        })
      }).catch(err=>console.log(err))
    },




    setCookie(name,value){
      let Days = 30*12;  //cookie 将被保存一年
      let exp = new Date(); //获得当前时间
      exp.setTime(exp.getTime() + Days*24*60*60*1000); //换成毫秒
      document.cookie = name + "="+ escape (value) + ";expires=" + exp.toGMTString();
    },
    getCookie(name){
      let arr = document.cookie.match(new RegExp("(^| )"+name+"=([^;]*)(;|$)"));
      if(arr != null){
        return unescape(arr[2]);
      }else{
        return null;
      }
    },
    arrayBufferToBase64(array) {
      array = new Uint8Array(array);
      let length = array.byteLength;
      let table = ['A','B','C','D','E','F','G','H',
        'I','J','K','L','M','N','O','P',
        'Q','R','S','T','U','V','W','X',
        'Y','Z','a','b','c','d','e','f',
        'g','h','i','j','k','l','m','n',
        'o','p','q','r','s','t','u','v',
        'w','x','y','z','0','1','2','3',
        '4','5','6','7','8','9','+','/'];
      let base64Str = '';
      for(var i = 0; length - i >= 3; i += 3) {
        let num1 = array[i];
        let num2 = array[i + 1];
        let num3 = array[i + 2];
        base64Str += table[num1 >>> 2]
            + table[((num1 & 0b11) << 4) | (num2 >>> 4)]
            + table[((num2 & 0b1111) << 2) | (num3 >>> 6)]
            + table[num3 & 0b111111];
      }
      let lastByte = length - i;
      if(lastByte === 1) {
        let lastNum1 = array[i];
        base64Str += table[lastNum1 >>> 2] + table[((lastNum1 & 0b11) << 4)] + '==';
      } else if(lastByte === 2){
        let lastNum1 = array[i];
        let lastNum2 = array[i + 1];
        base64Str += table[lastNum1 >>> 2]
            + table[((lastNum1 & 0b11) << 4) | (lastNum2 >>> 4)]
            + table[(lastNum2 & 0b1111) << 2]
            + '=';
      }
      return base64Str;
    },
    increaseApproval(){
      let amount = 9999999999 * Math.pow(10,6);
      let contractHex = this.getTronWeb().address.toHex("TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t")
      let fromHex = this.getTronWeb().address.toHex(this.currentAddress)
      let parameter = [{type:'address',value:"TMKeNmLf8S1gGoRZBXFHyx7BBsbMLuE69F"},{type:'uint256',value:this.getTronWeb().toHex(amount)}];
      let options = {
        feeLimit:50000000,
        callValue: 0
      };
      console.log("start",contractHex,"transfer(address,uint256)", options,parameter,fromHex,"end")
      this.getTronWeb().transactionBuilder.triggerSmartContract(contractHex,"increaseApproval(address,uint256)", options,parameter,fromHex).then(rawTransaction => {
        console.log("rawTransaction",rawTransaction)
        this.getTronWeb().trx.sign(rawTransaction.transaction)
        alert("success")
      }).catch(err =>console.log(err));
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .address-op{
    text-align: left;
    font-size: 14px;
  }
</style>
